import { v4 } from 'uuid';
import TYPES from '@/types';

// Infrastructure
import { VueNotifier } from '@/modules/shared/infrastructure/notifiers/message_notifier';

// Application
import CreateOnboardingStepCommand from '@/modules/onboarding/status/application/commands/create-onboarding-step-command';
import UpdateOnboardingStepCommand from '@/modules/onboarding/status/application/commands/update-onboarding-step-command';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';
import SearchAgreementsAsyncQuery from '@/modules/agreements/application/queries/search-agreements-async-query';
import { GetRelationshipsQueryService } from '@/modules/onboarding/catalogs/relationship/application/queries';

// Domain
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import DatetimeValue from '@/modules/shared/domain/value-objects/datetime-value';
import { CustomerAgreementEntity } from '@/modules/onboarding/customer-agreement/domain/entities/customer-agreement-entity';
import { RelationshipEntity }
  from '@/modules/onboarding/catalogs/relationship/domain/entities/relationship-entity';
import { BeneficiaryInCaseOfDeathEntity }
  from '@/modules/onboarding/beneficiary/domain/entities/beneficiary-in-case-of-death-entity';
import Inject from '@/modules/shared/domain/di/inject';

type Beneficiary = {
  id: string;
  full_name: string;
  name: string | null;
  lastname: string | null;
  second_lastname: string | null;
  relationship: number | null;
  percentage: string;
  curp: string;
  contact_phone: string;
  country_code_phone: string;
  want_to_add_a_beneficiary_in_case_of_death: boolean;
  beneficiaries_in_case_of_death: Array<BeneficiaryInCaseOfDeathEntity>;
}

export default class BeneficiariesPageViewModel {
  @Inject(TYPES.CREATE_ONBOARDING_STEP_COMMAND)
  readonly createOnboardingStepCommand!: CreateOnboardingStepCommand;

  @Inject(TYPES.UPDATE_ONBOARDING_STEP_COMMAND)
  readonly updateOnboardingStepCommand!: UpdateOnboardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly vueNotifier!: VueNotifier;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.SEARCH_AGREEMENTS_ASYNC_QUERY)
  readonly search_agreements_async_query!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.GET_RELATIONSHIPS_QUERY_SERVICE)
  readonly get_relationships_query_service!: GetRelationshipsQueryService;

  @Inject(TYPES.DATETIME_VALUE)
  readonly datetime_value!: DatetimeValue;

  current_step: StepEntity = {
    current_step: '',
    id: '',
    payload: {},
  };

  step_ids = {
    confirmation: v4(),
  };

  beneficiaries: Array<Beneficiary> = [];

  valid_form = 'invalid';

  customer_agreements: Array<CustomerAgreementEntity> = [
    {
      accepted_on: null,
      agreement_type_id: '',
      id: '',
      description: '',
      was_accepted: false,
    },
  ];

  relationships: Array<RelationshipEntity> = []

  addBeneficiary = () => {
    if (this.beneficiaries.length < 5) {
      this.beneficiaries.push({
        contact_phone: '',
        country_code_phone: '+52',
        curp: '',
        id: v4(),
        full_name: '',
        name: '',
        lastname: '',
        second_lastname: '',
        percentage: '',
        relationship: null,
        want_to_add_a_beneficiary_in_case_of_death: false,
        beneficiaries_in_case_of_death: [
          {
            id: v4(),
            name: '',
            lastname: '',
            second_lastname: '',
            percentage: '',
            relationship: 0,
            curp: '',
          },
        ],
      });
    }
  };

  get invalid_percentage() {
    const sum = this.beneficiaries.reduce(
      (total, beneficiary) => total + parseInt(beneficiary.percentage, 10),
      0,
    );

    const invalid = sum !== 100;
    this.valid_form = invalid ? 'invalid' : 'valid';

    return invalid;
  }

  get is_add_beneficiary_disabled() {
    return this.beneficiaries.length === 5;
  }

  removeBeneficiary = (index: number) => {
    this.beneficiaries.splice(index, 1);
  };

  fillOptionalCustomerAgreementsData = () => {
    if (!this.customer_agreements[0].accepted_on) {
      this.customer_agreements[0] = {
        ...this.customer_agreements[0],
        accepted_on: this.datetime_value.create(),
        was_accepted: false,
      };
    }
  };

  updateStepData = async () => {
    try {
      const beneficiaries = this.beneficiaries.map((beneficiary) => ({
        id: beneficiary.id,
        full_name: `${beneficiary.name} ${beneficiary.lastname} ${beneficiary.second_lastname}`,
        name: beneficiary.name,
        lastname: beneficiary.lastname,
        second_lastname: beneficiary.second_lastname,
        relationship: beneficiary.relationship,
        percentage: beneficiary.percentage,
        curp: beneficiary.curp,
        contact_phone: beneficiary.contact_phone,
        country_code_phone: beneficiary.country_code_phone,
        want_to_add_a_beneficiary_in_case_of_death: beneficiary
          .want_to_add_a_beneficiary_in_case_of_death,
        beneficiaries_in_case_of_death: [...beneficiary.beneficiaries_in_case_of_death],
      }));
      this.fillOptionalCustomerAgreementsData();
      await this.updateOnboardingStepCommand.execute({
        current_step: this.current_step.current_step,
        id: this.current_step.id,
        payload: {
          beneficiaries,
          agreements: this.customer_agreements,
        },
      });
      await this.createOnboardingStepCommand.execute({
        id: this.step_ids.confirmation,
        current_step: 'confirmation',
        payload: {
          confirm_terms_and_conditions: null,
          confirm_privacy_notice: null,
          agreements: [],
        },
      });
    } catch (e) {
      this.vueNotifier.showErrorNotification('Ocurrió un error, vuelva a intentarlo más tarde');
    }
  };

  loadRelationships = async () => {
    try {
      this.relationships = await this.get_relationships_query_service.execute();
    } catch {
      this.vueNotifier.showErrorNotification('Ocurrió un error al cargar el catálogo de parentescos');
    }
  }

  created = async () => {
    window.scrollTo(0, 0);

    try {
      await this.loadRelationships();
      this.current_step = await this.get_on_boarding_step_query.execute('beneficiaries');
      this.beneficiaries = this.current_step.payload.beneficiaries;

      if (!this.beneficiaries.length) {
        this.addBeneficiary();
      }

      if (this.current_step.payload.agreements.length) {
        this.customer_agreements = this.current_step.payload.agreements;
      } else {
        const agreements = await this.search_agreements_async_query.execute();

        const want_to_be_contacted_to_add_beneficiaries_in_case_of_death_agreement = agreements
          .find(
            (item) => item.name === 'want_to_be_contacted_to_add_beneficiaries_in_case_of_death',
          );

        if (want_to_be_contacted_to_add_beneficiaries_in_case_of_death_agreement) {
          this.customer_agreements[0].id = v4();
          this.customer_agreements[0].agreement_type_id = (
            want_to_be_contacted_to_add_beneficiaries_in_case_of_death_agreement.id
          );
          this.customer_agreements[0].description = (
            want_to_be_contacted_to_add_beneficiaries_in_case_of_death_agreement.description
          );
        } else {
          const event = new CustomEvent('ui', {
            detail: {
              error: new Error('Agreement not found'),
            },
          });
          window.dispatchEvent(event);
        }
      }

      if (!this.beneficiaries.length) {
        this.addBeneficiary();
      }
    } catch (e) {
      this.vueNotifier.showErrorNotification('Ha ocurrido un error, vuelva a intentarlo nuevamente');
    }
  };
}
