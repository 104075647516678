














































































































import { Component, Vue } from 'vue-property-decorator';
import Card from '../Card.vue';
import PersonalInfoViewModel
  from '@/vue-app/view-models/components/on-boarding/personal-info/personal-info-view-model';
import CustomAutocomplete from '@/vue-app/components/onboarding/CustomAutocomplete.vue';

@Component({
  name: 'PersonalInfo',
  components: { CustomAutocomplete, Card },
})
export default class PersonalInfo extends Vue {
  personal_info_view_model = Vue.observable(new PersonalInfoViewModel());

  async created() {
    await this.personal_info_view_model.initialize();
  }
}
