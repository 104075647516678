




















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import BankInformationPageViewModel
  from '@/vue-app/view-models/components/on-boarding/bank-information/bank-information-page-view-model';
import Card from '../Card.vue';

@Component({
  name: 'BankInformationPage',
  components: {
    Card,
    AddBankAccountComponent: () => import('./AddBankAccountComponent.vue'),
  },
})
export default class BankInformationPage extends Vue {
  bank_information_page_view_model = Vue.observable(new BankInformationPageViewModel());

  @Watch('bank_information_page_view_model.inputs.confirm_bank_information')
  onConfirmBankInformationChange(confirm: boolean) {
    this.bank_information_page_view_model.toggleConfirmBankInformationAgreement(confirm);
  }

  @Watch(
    'bank_information_page_view_model.inputs.want_to_be_contacted_to_add_another_bank_account',
  )
  onConfirmWantToAddAnotherBankAccountAgreement(confirm: boolean) {
    this.bank_information_page_view_model.toggleWantToBeContactedToAddAnotherBankAccountAgreement(
      confirm,
    );
  }

  async created() {
    window.scrollTo(0, 0);
    await this.bank_information_page_view_model.initialize();
  }
}
