import { requiredRule } from '@/vue-app/utils/form-rules';
import TYPES from '@/types';
import { v4 } from 'uuid';

// Infrastructure
import PersonPresenter
  from '@/modules/onboarding/person/infrastructure/presenters/person-presenter';

// Application
import { GetPersonQueryService } from '@/modules/onboarding/person/application/queries';
import SearchAgreementsQuery
  from '@/modules/agreements/application/queries/search-agreements-query';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';
import GetMaritalStatusesQuery from '@/modules/onboarding/catalogs/marital-status/application/queries/get-marital-statuses-query';

// Domain
import { MaritalStatusEntity } from '@/modules/onboarding/catalogs/marital-status/domain/entities/marital-status-entity';
import { PersonEntity } from '@/modules/onboarding/person/domain/entities/person-entity';
import { CustomerAgreementEntity }
  from '@/modules/onboarding/status/domain/entities/customer-agreement-entity';
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import DatetimeValue from '@/modules/shared/domain/value-objects/datetime-value';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class PersonalInfoViewModel {
  @Inject(TYPES.GET_PERSON_QUERY_SERVICE)
  private readonly get_person_query!: GetPersonQueryService;

  @Inject(TYPES.SEARCH_AGREEMENTS_QUERY)
  private readonly search_agreements_query!: SearchAgreementsQuery;

  @Inject(TYPES.GET_MARITAL_STATUSES_QUERY)
  readonly get_marital_statuses_query!: GetMaritalStatusesQuery;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.DATETIME_VALUE)
  private readonly datetime_value!: DatetimeValue;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  rules = {
    required: [requiredRule],
  };

  person_presenter?: PersonPresenter;

  is_loading = false;

  selected_marital_status_id = '';

  confirm_data = false;

  form_validity = false;

  person_entity: PersonEntity = {
    curp: '',
    updated_at: '',
    marital_status: {
      name: '',
    },
    elector_key: '',
    last_name: '',
    id: '',
    age: 0,
    created_at: '',
    date_of_birth: '',
    gender: {
      name: '',
    },
    second_last_name: '',
    nationality: {
      id: 0,
      name: '',
    },
    name: '',
    rfc: '',
  }

  user_agreement: CustomerAgreementEntity = {
    agreement_type_id: '',
    accepted_on: null,
    id: '',
    was_accepted: false,
    description: '',
  };

  current_step: StepEntity = {
    current_step: '',
    payload: {},
    id: '',
  };

  marital_statuses: Array<MaritalStatusEntity> = []

  initialize = async () => {
    this.is_loading = true;
    await this.loadMaritalStatutes();
    await this.loadPersonInformation();
    this.createPersonPresenter();
    await this.loadCurrentStep();
    await this.setAgreementData();
    this.is_loading = false;
  }

  loadPersonInformation = async () => {
    try {
      this.person_entity = await this.get_person_query.execute();
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar su información personal');
    }
  }

  loadCurrentStep = async () => {
    try {
      this.current_step = await this.get_on_boarding_step_query.execute('personal_information');
      this.confirm_data = this.current_step.payload.confirm_data.personal;
      this.selected_marital_status_id = this.current_step.payload.marital_status_id ?? '';
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar la información de la página');
    }
  }

  loadMaritalStatutes = async () => {
    try {
      this.marital_statuses = await this.get_marital_statuses_query.execute();
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar el catálogo del estado civil');
    }
  }

  setAgreementData = async () => {
    try {
      const all_agreements = await this.search_agreements_query.execute();
      const agreement = all_agreements.find(
        (item) => item.name === 'confirmation_of_personal_data',
      );

      if (agreement) {
        const user_agreement = this.current_step.payload.agreements.find(
          (item: CustomerAgreementEntity) => item.agreement_type_id === agreement.id,
        );

        if (user_agreement) {
          this.user_agreement = user_agreement;
        } else {
          this.user_agreement = {
            ...this.user_agreement,
            id: v4(),
            description: agreement.description,
            agreement_type_id: agreement.id,
          };
        }
      }
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar los acuerdos');
    }
  }

  onChangeConfirmData = () => {
    if (this.confirm_data) {
      this.user_agreement = {
        ...this.user_agreement,
        was_accepted: true,
        accepted_on: this.datetime_value.create(),
      };
    }
  }

  createPersonPresenter = () => {
    this.person_presenter = new PersonPresenter(this.person_entity);
  }
}
