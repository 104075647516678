



























































import { Component, Vue } from 'vue-property-decorator';
import BeneficiariesForm from './BeneficiariesForm.vue';
import Card from '../Card.vue';

import BeneficiariesPageViewModel from '@/vue-app/view-models/components/on-boarding/beneficiaries/beneficiaries-page-view-model';

@Component({
  name: 'BeneficiariesPage',
  components: { Card, BeneficiariesForm },
})
export default class BeneficiariesPage extends Vue {
  readonly beneficiaries_page_view_model = Vue.observable(
    new BeneficiariesPageViewModel(),
  );

  created() {
    this.beneficiaries_page_view_model.created();
  }
}
