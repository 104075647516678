import { v4 } from 'uuid';
import { bankAccountFormat, requiredRule } from '@/vue-app/utils/form-rules';
import TYPES from '@/types';

// Application
import SearchInstitutionsQuery
  from '@/modules/onboarding/institutions/application/queries/search-institutions-query';
import SearchAgreementsQuery
  from '@/modules/agreements/application/queries/search-agreements-query';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';
import CreateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/create-onboarding-step-command';
import UpdateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/update-onboarding-step-command';

// Domain
import { CustomerAgreementEntity } from '@/modules/onboarding/customer-agreement/domain/entities/customer-agreement-entity';
import { AgreementEntity } from '@/modules/agreements/domain/entities/agreement-entity';
import { SearchInstitutionDto } from '@/modules/onboarding/institutions/domain/dto/search-institution-dto';
import { InstitutionPaginatedEntity } from '@/modules/onboarding/institutions/domain/entities/institution-paginated-entity';
import { InstitutionEntity } from '@/modules/onboarding/institutions/domain/entities/institution-entity';
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import DatetimeValue from '@/modules/shared/domain/value-objects/datetime-value';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type ExtraBankAccount = {
  type_of_bank_account: string;
  siap_institution_id: number | null;
  bank_account: string;
  financial_institution: string;
  bank_statement_file_id: null | string;
  confirm_new_account: boolean;
  foreign_account: {
    beneficiary: string;
    siap_institution_id: number | null;
    siap_institution_name: string;
    id_cat_pais: string;
    account_or_iban: string;
    aba: string;
    swift: string;
    for_further_credit_to: string;
    currency_id: number;
    currency_description: string;
  };
}

export default class BankInformationPageViewModel {
  @Inject(TYPES.SEARCH_SIAP_INSTITUTIONS_QUERY)
  private readonly search_siap_institutions_query!: SearchInstitutionsQuery;

  @Inject(TYPES.SEARCH_AGREEMENTS_QUERY)
  private readonly search_agreements_query!: SearchAgreementsQuery;

  @Inject(TYPES.DATETIME_VALUE)
  datetimeValue!: DatetimeValue;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.CREATE_ONBOARDING_STEP_COMMAND)
  readonly createOnboardingStepCommand!: CreateOnboardingStepCommand;

  @Inject(TYPES.UPDATE_ONBOARDING_STEP_COMMAND)
  readonly updateOnboardingStepCommand!: UpdateOnboardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  private readonly notifier!: MessageNotifier;

  readonly step_ids = {
    beneficiaries: v4(),
  };

  loading = false;

  current_step: StepEntity = {
    current_step: '',
    id: '',
    payload: {},
  };

  inputs = {
    bank_account: '',
    confirm_bank_information: false,
    want_to_be_contacted_to_add_another_bank_account: false,
    want_to_add_another_bank_account: false,
  };

  user_agreements: Array<CustomerAgreementEntity> = [
    {
      was_accepted: false,
      id: '',
      accepted_on: null,
      agreement_type_id: '',
      description: '',
    },
    {
      was_accepted: false,
      id: '',
      accepted_on: null,
      agreement_type_id: '',
      description: '',
    },
  ]

  add_other_bank_account_inputs: ExtraBankAccount = {
    type_of_bank_account: 'national',
    siap_institution_id: null,
    bank_account: '',
    financial_institution: '',
    bank_statement_file_id: null,
    confirm_new_account: false,
    foreign_account: {
      beneficiary: '',
      siap_institution_id: null,
      siap_institution_name: '',
      id_cat_pais: '2',
      account_or_iban: '',
      aba: '',
      swift: '',
      for_further_credit_to: '',
      currency_id: 0,
      currency_description: '',
    },
  }

  institution_paginated_entity: InstitutionPaginatedEntity = {
    items: [],
    pagination: {
      page: 1,
      next_page: 1,
      previous_page: 1,
      page_size: 10,
      total_pages: 1,
      total_items: 0,
    },
  }

  selected_institution_entity: InstitutionEntity = {
    institution_id: 0,
    institution_type_id: 0,
    risk_id: 0,
    description: '',
    quality: '',
    company_name: '',
    day_type: '',
    national: '',
    status: '',
    cta_indeval: null,
    nemonico: null,
    bank_code: null,
  }

  search_institution_dto: SearchInstitutionDto = {
    query_spec: [
      { field: 'NACIONAL', op: 'eq', value: 'N' },
      { field: 'ESTATUS', op: 'eq', value: 'A' },
      { field: 'DESCRIPCION_INSTITUCION', op: 'ilike', value: '' },
    ],
    pagination_spec: { page: 1, page_size: 10 },
    sort_spec: [
      { field: 'DESCRIPCION_INSTITUCION', direction: 'asc', nulls: 'first' },
    ],
  }

  current_search_bank = '';

  search_bank_prev_value = '';

  is_bank_searcher_loading = false;

  timer?: NodeJS.Timer;

  rules = {
    required: [requiredRule],
    bank_account: [requiredRule, bankAccountFormat],
  };

  agreements: Array<AgreementEntity> = []

  get valid_bank_data() {
    return (
      this.selected_institution_entity && this.selected_institution_entity.institution_id
      && this.inputs.bank_account
        .replaceAll(' ', '')
        .match(/[0-9]{18}/)
    );
  }

  get the_search_bank_value_changed() {
    return this.current_search_bank !== this.search_bank_prev_value;
  }

  get there_are_more_pages_search_bank_institution() {
    return this.search_institution_dto.pagination_spec
      .page < this.institution_paginated_entity.pagination.total_pages;
  }

  searchBankInstitutions = async (clean_items: boolean) => {
    try {
      if (this.current_search_bank.length >= 2) {
        this.is_bank_searcher_loading = true;
        if (clean_items) {
          this.institution_paginated_entity = {
            items: [],
            pagination: {
              next_page: 0,
              page: 0,
              page_size: 0,
              previous_page: 0,
              total_items: 0,
              total_pages: 0,
            },
          };
          this.search_institution_dto.pagination_spec.page = 1;
        }
        this.search_bank_prev_value = this.current_search_bank;
        const index = this.search_institution_dto.query_spec.findIndex((item) => item.field === 'DESCRIPCION_INSTITUCION');
        this.search_institution_dto.query_spec[index].value = `%${this.current_search_bank}%`;
        const items = await this.search_siap_institutions_query
          .execute(this.search_institution_dto);
        this.institution_paginated_entity = {
          items: [...this.institution_paginated_entity.items, ...items.items],
          pagination: { ...items.pagination },
        };
        this.is_bank_searcher_loading = false;
      }
    } catch {
      this.notifier.showErrorNotification('Ocurrió un error al obtener el catálogo de bancos');
    }
  }

  lazyLoadSearchBankInstitutions = async (entries: Array<any>) => {
    if (entries[0].intersectionRatio
      && this.there_are_more_pages_search_bank_institution
      && !this.the_search_bank_value_changed) {
      this.search_institution_dto.pagination_spec
        .page = this.institution_paginated_entity.pagination.next_page;
      await this.searchBankInstitutions(false);
    }
  }

  delaySearchBankInstitutions = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      if (this.current_search_bank && this.current_search_bank.length >= 2) {
        await this.searchBankInstitutions(true);
      }
    }, 1500);
  }

  getSearchBankText = (item: InstitutionEntity) => `${item.description}`;

  toggleConfirmBankInformationAgreement(confirm: boolean) {
    this.user_agreements[0] = {
      ...this.user_agreements[0],
      accepted_on: confirm
        ? this.datetimeValue.create()
        : this.user_agreements[0].accepted_on,
      was_accepted: confirm,
    };
  }

  toggleWantToBeContactedToAddAnotherBankAccountAgreement(confirm: boolean) {
    this.user_agreements[1] = {
      ...this.user_agreements[1],
      accepted_on: confirm
        ? this.datetimeValue.create()
        : this.user_agreements[1].accepted_on,
      was_accepted: confirm,
    };
  }

  setUserAgreementIdentifiersOnAgreementsLoaded = (new_agreements: Array<AgreementEntity>) => {
    const confirm_bank_information_agreement = new_agreements.find(
      (item) => item.name === 'confirm_bank_information',
    );
    const want_to_be_contacted_to_add_another_bank_account_agreement = new_agreements.find(
      (item) => item.name === 'want_to_be_contacted_to_add_another_bank_account',
    );

    if (confirm_bank_information_agreement) {
      const user_agreement = this.current_step.payload.agreements.find(
        (item: CustomerAgreementEntity) => (
          item.agreement_type_id === confirm_bank_information_agreement.id
        ),
      );

      if (user_agreement) {
        this.user_agreements[0] = user_agreement;
      } else {
        this.user_agreements[0] = {
          ...this.user_agreements[0],
          id: v4(),
          description: confirm_bank_information_agreement.description,
          agreement_type_id: confirm_bank_information_agreement.id,
        };
      }
    }

    if (want_to_be_contacted_to_add_another_bank_account_agreement) {
      const user_agreement = this.current_step.payload.agreements.find(
        (item: CustomerAgreementEntity) => (
          item.agreement_type_id === want_to_be_contacted_to_add_another_bank_account_agreement.id
        ),
      );

      if (user_agreement) {
        this.user_agreements[1] = user_agreement;
      } else {
        this.user_agreements[1] = {
          ...this.user_agreements[1],
          id: v4(),
          description: want_to_be_contacted_to_add_another_bank_account_agreement.description,
          agreement_type_id: want_to_be_contacted_to_add_another_bank_account_agreement.id,
          accepted_on: this.datetimeValue.create(),
        };
      }
    }
  }

  fillOptionalCustomerAgreementsData = () => {
    if (!this.user_agreements[1].accepted_on) {
      this.user_agreements[1] = {
        ...this.user_agreements[1],
        accepted_on: this.datetimeValue.create(),
        was_accepted: false,
      };
    }
  }

  updateStepData = async () => {
    this.loading = true;

    try {
      this.fillOptionalCustomerAgreementsData();
      await this.updateOnboardingStepCommand.execute({
        current_step: this.current_step.current_step,
        id: this.current_step.id,
        payload: {
          ...this.inputs,
          siap_institution_id: this.selected_institution_entity.institution_id,
          financial_institution: this.selected_institution_entity.description,
          bank_account: this.inputs.bank_account,
          agreements: this.user_agreements,
          extra_bank_account: { ...this.add_other_bank_account_inputs },
        },
      });
      await this.createOnboardingStepCommand.execute({
        id: this.step_ids.beneficiaries,
        current_step: 'beneficiaries',
        payload: {
          beneficiaries: [],
          agreements: [],
        },
      });
    } finally {
      this.loading = false;
    }
  };

  loadAgreements = async () => {
    try {
      this.agreements = await this.search_agreements_query.execute();
    } catch {
      this.notifier.showErrorNotification('Ocurrió un error al obtener los acuerdos');
    }
  }

  initialize = async () => {
    this.current_step = await this.get_on_boarding_step_query.execute('bank_information');
    this.inputs = {
      bank_account: this.current_step.payload.bank_account,
      confirm_bank_information: this.current_step.payload.confirm_bank_information,
      want_to_be_contacted_to_add_another_bank_account:
        this.current_step.payload.want_to_be_contacted_to_add_another_bank_account,
      want_to_add_another_bank_account: this.current_step.payload.want_to_add_another_bank_account,
    };
    if (this.current_step.payload.extra_bank_account) {
      this.add_other_bank_account_inputs = this.current_step.payload.extra_bank_account;
    }
    this.current_search_bank = this.current_step.payload.financial_institution;
    if (this.current_search_bank) {
      await this.searchBankInstitutions(true);
      this.selected_institution_entity = { ...this.institution_paginated_entity.items[0] };
    }
    await this.loadAgreements();
    this.setUserAgreementIdentifiersOnAgreementsLoaded(this.agreements);
  }
}
