














































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import Card from '../Card.vue';
import CustomAutocomplete from '@/vue-app/components/onboarding/CustomAutocomplete.vue';
import WorkingInformationViewModel
  from '@/vue-app/view-models/components/on-boarding/financial-information/working-information-view-model';

@Component({
  name: 'WorkingInformation',
  components: {
    PubliclyTradedCompanies: () => import('@/vue-app/components/onboarding/financial-information/PubliclyTradedCompanies.vue'),
    Card,
    CustomAutocomplete,
  },
})
export default class WorkingInformation extends Vue {
  working_information_view_model = Vue.observable(new WorkingInformationViewModel());

  @Watch('working_information_view_model.inputs.activity_or_line_of_business')
  onInputsActivityOrLineOfBusinessChange(activity_id: number) {
    this.working_information_view_model.setInputsActivityOrLineOfBusinessData(activity_id);
  }

  @Watch('working_information_view_model.inputs.has_issuance_of_securities')
  onInputsHasIssuanceOfSecuritiesChange(new_has_issuance_of_securities: boolean) {
    this.working_information_view_model.toggleUserAgreement(new_has_issuance_of_securities);
  }

  async created() {
    await this.working_information_view_model.initialize();
  }
}
