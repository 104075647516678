











































import { Component, Vue } from 'vue-property-decorator';
import { requiredRule } from '@/vue-app/utils/form-rules';
import Card from '../Card.vue';
import CustomAutocomplete from '@/vue-app/components/onboarding/CustomAutocomplete.vue';
import TYPES from '@/types';

// Application
import { GetSourcesOfResourcesQueryService }
  from '@/modules/onboarding/catalogs/source-of-resources/application/queries';
import { GetApproximateValuesQueryService }
  from '@/modules/onboarding/catalogs/approximate-value/application/queries';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';

// Domain
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import Inject from '@/modules/shared/domain/dependency_injection/inject';

@Component({
  name: 'SourceOfResources',
  components: { Card, CustomAutocomplete },
})
export default class SourceOfResources extends Vue {
  @Inject(TYPES.GET_SOURCES_OF_RESOURCES_QUERY_SERVICE)
  readonly getSourcesOfResourcesQueryService!: GetSourcesOfResourcesQueryService;

  @Inject(TYPES.GET_APPROXIMATE_VALUES_QUERY_SERVICE)
  readonly getApproximateValuesQueryService!: GetApproximateValuesQueryService;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  current_step: StepEntity = {
    current_step: '',
    payload: {},
    id: '',
  };

  inputs: any = {
    source_of_resources: null,
    annual_income: null,
  };

  rules = {
    required: [requiredRule],
  }

  get sourceOfResources() {
    return this.getSourcesOfResourcesQueryService.execute();
  }

  get approximateValues() {
    return this.getApproximateValuesQueryService.execute();
  }

  async created() {
    this.current_step = await this.get_on_boarding_step_query.execute('financial_information');
    this.inputs = {
      source_of_resources:
        this.current_step.payload.financial_information.source_of_resources,
      annual_income:
        this.current_step.payload.financial_information.annual_income,
    };
  }
}
