import { v4 } from 'uuid';

import TYPES from '@/types';
import { notNull, onlyNumbers, requiredRule } from '@/vue-app/utils/form-rules';

// Application
import SearchAgreementsQuery
  from '@/modules/agreements/application/queries/search-agreements-query';
import { GetEconomicActivitiesQueryService }
  from '@/modules/onboarding/catalogs/economic-activity/application/queries';
import { GetProfessionsQueryService }
  from '@/modules/onboarding/catalogs/profession/application/queries';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';

// Domain
import { CustomerAgreementEntity }
  from '@/modules/onboarding/status/domain/entities/customer-agreement-entity';
import { AgreementEntity } from '@/modules/agreements/domain/entities/agreement-entity';
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import {
  ProfessionEntity,
} from '@/modules/onboarding/catalogs/profession/domain/entities/profession-entity';
import {
  EconomicActivityEntity,
} from '@/modules/onboarding/catalogs/economic-activity/domain/entities/economic-activity-entity';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import DatetimeValue from '@/modules/shared/domain/value-objects/datetime-value';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class WorkingInformationViewModel {
  @Inject(TYPES.SEARCH_AGREEMENTS_QUERY)
  private readonly search_agreements_query!: SearchAgreementsQuery;

  @Inject(TYPES.GET_ECONOMIC_ACTIVITIES_QUERY_SERVICE)
  private readonly search_economic_activities_query!: GetEconomicActivitiesQueryService;

  @Inject(TYPES.GET_PROFESSIONS_QUERY_SERVICE)
  private readonly search_professions_query!: GetProfessionsQueryService;

  @Inject(TYPES.DATETIME_VALUE)
  private datetime_value!: DatetimeValue;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly notifier!: MessageNotifier;

  private user_agreement: CustomerAgreementEntity = {
    accepted_on: null,
    agreement_type_id: '',
    was_accepted: false,
    id: '',
    description: '',
  };

  current_step: StepEntity = {
    id: '',
    current_step: '',
    payload: {},
  };

  publicly_traded_companies_modal = {
    is_open: false,
  }

  inputs = {
    activity_or_line_of_business: '',
    activity_or_line_of_business_vulneravility: null as number | null,
    activity_or_line_of_business_high_flow: null as number | null,
    profession: '',
    company_where_work: '',
    has_issuance_of_securities: null as boolean | null,
    job: '',
    admission_job_year: '',
  };

  rules = {
    required: [requiredRule],
    not_null: [notNull],
    only_numbers: [requiredRule, onlyNumbers],
    admission_job_year: [
      requiredRule,
      (value: string) => (new Date().getFullYear() - parseInt(value, 10) <= 80 && new Date().getFullYear() - parseInt(value, 10) >= 0) || 'Año inválido',
    ],
  };

  agreements: Array<AgreementEntity> = []

  professions: Array<ProfessionEntity> = []

  economic_activities: Array<EconomicActivityEntity> = []

  openPubliclyTradedCompaniesModal = () => {
    this.publicly_traded_companies_modal.is_open = true;
  }

  toggleUserAgreement = (was_accepted: boolean) => {
    this.user_agreement.was_accepted = was_accepted;
    this.user_agreement.accepted_on = this.datetime_value.create();
  }

  loadAgreements = async () => {
    try {
      this.agreements = await this.search_agreements_query.execute();
    } catch {
      this.notifier.showErrorNotification('Ocurrió un error al obtener los acuerdos');
    }
  }

  setUserAgreementData = () => {
    const agreement = this.agreements.find(
      (item) => item.name === 'work_company_has_work_issuance',
    );

    if (agreement) {
      const user_agreement = this.current_step.payload.agreements.find(
        (item: CustomerAgreementEntity) => item.agreement_type_id === agreement.id,
      );

      if (user_agreement) {
        this.user_agreement = user_agreement;
      } else {
        this.user_agreement.id = v4();
        this.user_agreement.agreement_type_id = agreement.id;
        this.user_agreement.description = agreement.description;
      }
    }
  }

  setInputsActivityOrLineOfBusinessData = (activity_id: number) => {
    const activity = this.economic_activities.find(
      (item) => item.id === activity_id,
    );

    if (activity) {
      this.inputs.activity_or_line_of_business_vulneravility = activity.vulnerable;
      this.inputs.activity_or_line_of_business_high_flow = activity.cash_flow;
    }
  }

  initialize = async () => {
    this.current_step = await this.get_on_boarding_step_query.execute('financial_information');

    this.setInputsData(this.current_step.payload);
    await this.loadAgreements();
    this.setUserAgreementData();
    await this.loadProfessions();
    await this.loadEconomicActivities();
  }

  loadProfessions = async () => {
    try {
      this.professions = await this.search_professions_query.execute();
    } catch {
      this.notifier.showErrorNotification('Ocurrió un error al obtener las ocupaciones');
    }
  }

  loadEconomicActivities = async () => {
    try {
      this.economic_activities = await this.search_economic_activities_query.execute();
    } catch {
      this.notifier.showErrorNotification('Ocurrió un error al obtener las actividades economicas');
    }
  }

  private setInputsData(data: Record<string, any>) {
    this.inputs = {
      activity_or_line_of_business: data.financial_information.activity_or_line_of_business,
      activity_or_line_of_business_vulneravility:
        data.financial_information.activity_or_line_of_business_vulneravility,
      activity_or_line_of_business_high_flow:
        data.financial_information.activity_or_line_of_business_vulneravility,
      profession: data.financial_information.profession,
      company_where_work: data.financial_information.company_where_work,
      has_issuance_of_securities: data.financial_information.has_issuance_of_securities,
      job: data.financial_information.job,
      admission_job_year: data.financial_information.admission_job_year,
    };
  }
}
