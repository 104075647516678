















































































































































import { Component, Vue } from 'vue-property-decorator';
import { v4 } from 'uuid';
import Card from '../Card.vue';
import { requiredRule } from '@/vue-app/utils/form-rules';
import TYPES from '@/types';
import TransactionalityViewModel from '@/vue-app/view-models/components/on-boarding/financial-information/transactionality-view-model';

// Application
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';

// Domain
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import Inject from '@/modules/shared/domain/di/inject';

@Component({
  name: 'Transactionality',
  components: { Card },
})
export default class Transactionality extends Vue {
  private readonly transactionality_view_model = Vue.observable(new TransactionalityViewModel());

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  current_step: StepEntity = {
    current_step: '',
    payload: {},
    id: '',
  };

  inputs: any = {
    expected_transactionality_id: null,
    estimated_monthly_deposits: '',
    estimated_monthly_withdrawals: '',
    estimated_monthly_amount_deposits: '',
    estimated_monthly_amount_withdrawals: '',
    initial_investment: null,
    maximum_expected_investment: null,
  };

  rules = {
    required: [requiredRule],
  };

  async created() {
    this.current_step = await this.get_on_boarding_step_query.execute('financial_information');
    this.inputs = {
      expected_transactionality_id:
        this.current_step.payload.financial_information.expected_transactionality_id,
      estimated_monthly_deposits:
        this.current_step.payload.financial_information.estimated_monthly_deposits,
      estimated_monthly_withdrawals:
        this.current_step.payload.financial_information.estimated_monthly_withdrawals,
      estimated_monthly_amount_deposits:
        this.current_step.payload.financial_information.estimated_monthly_amount_deposits,
      estimated_monthly_amount_withdrawals:
        this.current_step.payload.financial_information.estimated_monthly_amount_withdrawals,
      initial_investment:
        this.current_step.payload.financial_information.initial_investment,
      maximum_expected_investment:
        this.current_step.payload.financial_information.maximum_expected_investment,
    };

    if (!this.inputs.expected_transactionality_id) {
      this.inputs.expected_transactionality_id = v4();
    }
  }
}
