






















import { Component, Vue } from 'vue-property-decorator';
import { v4 } from 'uuid';
import PersonalInfo from './PersonalInfo.vue';
import AddressInfo from './AddressInfo.vue';
import ContactInfo from './ContactInfo.vue';
import TYPES from '@/types';

// Application
import CreateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/create-onboarding-step-command';
import UpdateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/update-onboarding-step-command';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';

// Domain
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import Inject from '@/modules/shared/domain/di/inject';
import { VueNotifier } from '@/modules/shared/infrastructure/notifiers/message_notifier';

@Component({
  name: 'PersonalInfoPage1',
  components: {
    PersonalInfo,
    AddressInfo,
    ContactInfo,
  },
})
export default class PersonalInfoPage1 extends Vue {
  @Inject(TYPES.CREATE_ONBOARDING_STEP_COMMAND)
  readonly createOnboardingStepCommand!: CreateOnboardingStepCommand;

  @Inject(TYPES.UPDATE_ONBOARDING_STEP_COMMAND)
  readonly updateOnboardingStepCommand!: UpdateOnboardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly vueNotifier!: VueNotifier;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  $refs!: {
    personal_info: Vue;
    address_info: Vue;
    contact_info: Vue;
  };

  current_step: StepEntity = {
    payload: {},
    current_step: '',
    id: '',
  };

  step_ids = {
    tax_information: v4(),
  };

  async updateStepData() {
    const personal_info_payload: any = {
      confirm_data: {
        personal: this.$refs.personal_info.$data.personal_info_view_model.confirm_data,
        address: this.$refs.address_info.$data.confirm_data,
      },
      personal_information: {
        allow_whatsapp_contact: this.$refs.contact_info.$data.allow_whatsapp,
      },
      agreements: [
        this.$refs.personal_info.$data.personal_info_view_model.user_agreement,
        this.$refs.address_info.$data.user_agreement,
        this.$refs.contact_info.$data.user_agreement,
      ],
      addresses: [this.$refs.address_info.$data.inputs],
      cellphones: [this.$refs.contact_info.$data.cell_phone],
      cve_promotor: null,
      marital_status_id: this.$refs.personal_info.$data.personal_info_view_model
        .selected_marital_status_id,
    };

    if (this.$refs.contact_info.$data.phones.office.phone.number) {
      personal_info_payload.phones = [{
        ...this.$refs.contact_info.$data.phones.office,
        customer_phone_default: true,
      }];
    }

    if (this.$refs.contact_info.$data.phones.home.phone.number) {
      if (personal_info_payload.phones) {
        personal_info_payload.phones.push(this.$refs.contact_info.$data.phones.home);
      } else {
        personal_info_payload.phones = [{
          ...this.$refs.contact_info.$data.phones.home,
          customer_phone_default: true,
        }];
      }
    }

    await this.updateOnboardingStepCommand.execute({
      id: this.current_step.id,
      current_step: this.current_step.current_step,
      payload: personal_info_payload,
    });
    await this.createOnboardingStepCommand.execute({
      id: this.step_ids.tax_information,
      current_step: 'tax_information',
      payload: {
        personal_information: {
          other_nationality: '',
          has_another_nationality: false,
          residence: '',
          usa_fiscal_responsibility: false,
          tax_id: '',
          verify_fiscal_address: false,
        },
        person: {
          id: '',
          rfc: '',
          fiel: '',
        },
        addresses: [],
        agreements: [],
      },
    });
  }

  async created() {
    window.scrollTo(0, 0);
    this.current_step = await this.get_on_boarding_step_query.execute('personal_information');
  }
}
