import TYPES from '@/types';

// Application
import PermissionsStatusSubscription
  from '@/modules/permissions/status/application/subscriptions/permissions-status-subscription';
import GetPermissionsStatusService
  from '@/modules/permissions/status/application/services/get-permissions-status-service';
import GeolocationService from '@/modules/geolocation/application/services/geolocation-service';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { Geolocation } from '@/modules/shared/domain/geolocation';

export default class GeolocationPermissionModalViewModel {
  @Inject(TYPES.PERMISSIONS_STATUS_SUBSCRIPTION)
  readonly permissionStatusSubscription!: PermissionsStatusSubscription;

  @Inject(TYPES.GET_PERMISSIONS_STATUS_SERVICE)
  readonly getPermissionStatusService!: GetPermissionsStatusService;

  @Inject(TYPES.GEOLOCATION_SERVICE)
  readonly geolocation_service!: GeolocationService;

  @Inject(TYPES.GEOLOCATION)
  readonly geolocation!: Geolocation;

  HOW_TO_ACTIVATE_LOCATION_PDF_URL = `${process.env.VUE_APP_GCP_PUBLIC_URL}/email-templates/documentos/activiar_geolocalizacion.pdf`

  is_open = false;

  initialize = () => {
    this.permissionStatusSubscription.execute();
    setTimeout(() => {
      this.handlePermissionChange(this.geolocationPermissionGranted);
    }, 1000);
  }

  get geolocationPermissionGranted() {
    return this.getPermissionStatusService.execute().geolocation;
  }

  requestPermission = async () => {
    await this.geolocation.getCurrentPosition();
  }

  handlePermissionChange = (new_permission_status: boolean) => {
    this.is_open = !new_permission_status;
    if (!this.is_open) {
      this.geolocation_service.setGeolocationStatus(true);
    }
  }
}
