import Vue from 'vue';
import TYPES from '@/types';
import { v4 } from 'uuid';
import {
  curpFormat,
  percentageFormat,
  phoneFormat,
  requiredRule,
  notNull,
} from '@/vue-app/utils/form-rules';

// APPLICATION
import { GetRelationshipsQueryService } from '@/modules/onboarding/catalogs/relationship/application/queries';

// DOMAIN
import { RelationshipEntity }
  from '@/modules/onboarding/catalogs/relationship/domain/entities/relationship-entity';
import { BeneficiaryInCaseOfDeathEntity }
  from '@/modules/onboarding/beneficiary/domain/entities/beneficiary-in-case-of-death-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type Beneficiary = {
  id: string;
  full_name: string;
  name: string | null;
  lastname: string | null;
  second_lastname: string | null;
  relationship: number | null;
  percentage: string;
  curp: string;
  contact_phone: string;
  country_code_phone: string;
  want_to_add_a_beneficiary_in_case_of_death: boolean;
  beneficiaries_in_case_of_death: Array<BeneficiaryInCaseOfDeathEntity>;
}

export class BeneficiariesFormViewModel {
  @Inject(TYPES.GET_RELATIONSHIPS_QUERY_SERVICE)
  readonly get_relationships_query_service!: GetRelationshipsQueryService;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  beneficiary!: Beneficiary;

  rules = {
    required: [requiredRule],
    curp: [requiredRule, curpFormat],
    percentage: [percentageFormat],
    phone_format: [requiredRule, phoneFormat],
    not_null: [notNull],
  };

  user_wants_add_beneficiary_in_case_of_death_options = [
    {
      label: 'Sí',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ]

  relationships: Array<RelationshipEntity> = []

  form_index = 0;

  show_form = false;

  valid_total_percent = false;

  view!: Vue;

  get percentage() {
    return this.beneficiary.percentage ? `${this.beneficiary.percentage}%` : '0%';
  }

  get full_name() {
    return `${this.beneficiary.name} ${this.beneficiary.lastname} ${this.beneficiary.second_lastname}`;
  }

  get beneficiary_text() {
    return this.beneficiary.name ? this.full_name : `Beneficiario ${this.form_index + 1}`;
  }

  get show_remove_btn() {
    return this.form_index > 0;
  }

  get is_add_beneficiary_in_case_of_death_disabled() {
    return this.beneficiary.beneficiaries_in_case_of_death.length === 3;
  }

  constructor(view: Vue) {
    this.view = view;
  }

  setBeneficiary = (beneficiary: Beneficiary) => {
    this.beneficiary = beneficiary;
  };

  setFormIndex = (index: number) => {
    this.form_index = index;
  };

  curpToUpperCase = () => {
    this.beneficiary.curp = this.beneficiary.curp.toUpperCase();
  };

  toggleFormCard = () => {
    this.show_form = !this.show_form;
  };

  removeBeneficiaryInCaseOfDeath = (index: number) => {
    this.beneficiary.beneficiaries_in_case_of_death.splice(index, 1);
  };

  addBeneficiaryInCaseOfDeath = () => {
    if (this.beneficiary.beneficiaries_in_case_of_death.length < 3) {
      this.beneficiary.beneficiaries_in_case_of_death.push({
        curp: '',
        id: v4(),
        name: '',
        lastname: '',
        second_lastname: '',
        percentage: '',
        relationship: 0,
      });
    }
  };

  get invalid_percentage() {
    const sum = this.beneficiary.beneficiaries_in_case_of_death.reduce(
      (total, beneficiary) => total + parseInt(beneficiary.percentage, 10),
      0,
    );

    const invalid = sum !== 100;
    this.valid_total_percent = !invalid;

    return invalid;
  }

  selectPhoneContactCountryCode = (country_code: string) => {
    this.beneficiary.country_code_phone = country_code;
  }

  loadRelationships = async () => {
    try {
      this.relationships = await this.get_relationships_query_service.execute();
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al obtener el catálogo de parentescos');
    }
  }

  initialize = async () => {
    await this.loadRelationships();
  }
}
