




































































import { Vue, Component, Watch } from 'vue-property-decorator';
import GeolocationPermissionModalViewModel
  from '@/vue-app/view-models/components/geolocation/geolocation-permission-modal-view-model';

@Component({ name: 'GeolocationPermissionModal' })
export default class GeolocationPermissionModal extends Vue {
  geolocation_permission_view_model = Vue.observable(new GeolocationPermissionModalViewModel());

  @Watch('geolocation_permission_view_model.geolocationPermissionGranted')
  handlePermissionChange(new_permission_status: boolean) {
    this.geolocation_permission_view_model.handlePermissionChange(new_permission_status);
  }

  created() {
    this.geolocation_permission_view_model.initialize();
  }
}
