
























































































































































































































import {
  Component, Vue, PropSync, Prop, Watch,
} from 'vue-property-decorator';

import Card from '../Card.vue';
import PhoneCountryCodeSelect from '@/vue-app/components/onboarding/personal-info/PhoneCountryCodeSelect.vue';
import CustomAutocomplete from '@/vue-app/components/onboarding/CustomAutocomplete.vue';
import { BeneficiariesFormViewModel } from '@/vue-app/view-models/components/on-boarding/beneficiaries/beneficiaries-form-view-model';

// DOMAIN
import { BeneficiaryInCaseOfDeathEntity }
  from '@/modules/onboarding/beneficiary/domain/entities/beneficiary-in-case-of-death-entity';

type Beneficiary = {
  id: string;
  full_name: string;
  name: string | null;
  lastname: string | null;
  second_lastname: string | null;
  relationship: number | null;
  percentage: string;
  curp: string;
  contact_phone: string;
  country_code_phone: string;
  want_to_add_a_beneficiary_in_case_of_death: boolean;
  beneficiaries_in_case_of_death: Array<BeneficiaryInCaseOfDeathEntity>;
}

@Component({
  name: 'BeneficiariesForm',
  components: {
    PhoneCountryCodeSelect,
    CustomAutocomplete,
    Card,
    BeneficiariesInCaseOfDeathForm: () => import('./BeneficiariesInCaseOfDeathForm.vue'),
  },
})
export default class BeneficiariesForm extends Vue {
  @PropSync('beneficiary')
  synced_beneficiary!: Beneficiary;

  @Prop(Number) index: number | undefined;

  beneficiaries_form_view_model = Vue.observable(new BeneficiariesFormViewModel(this));

  handleRemove() {
    this.$emit('handleRemove', this.index);
  }

  created() {
    this.beneficiaries_form_view_model.initialize();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.beneficiaries_form_view_model.setFormIndex(this.index!);
    this.beneficiaries_form_view_model.setBeneficiary(this.synced_beneficiary);
  }

  @Watch('synced_beneficiary', { deep: true })
  setSyncedBeneficiary() {
    this.beneficiaries_form_view_model.setBeneficiary(this.synced_beneficiary);
  }
}
