






















/* eslint-disable max-len */

import { Component, Vue } from 'vue-property-decorator';
import { v4 } from 'uuid';
import SourceOfResources from './SourceOfResources.vue';
import Transactionality from './Transactionality.vue';
import WorkingInformation from './WorkingInformation.vue';
import Disclaimers from './Disclaimers.vue';
import TYPES from '@/types';

// Application
import CreateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/create-onboarding-step-command';
import UpdateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/update-onboarding-step-command';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';

// Domain
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import Inject from '@/modules/shared/domain/di/inject';
import { VueNotifier } from '@/modules/shared/infrastructure/notifiers/message_notifier';

@Component({
  name: 'FinancialInformationPage',
  components: {
    SourceOfResources,
    Transactionality,
    WorkingInformation,
    Disclaimers,
  },
})
export default class FinancialInformationPage extends Vue {
  @Inject(TYPES.CREATE_ONBOARDING_STEP_COMMAND)
  readonly createOnboardingStepCommand!: CreateOnboardingStepCommand;

  @Inject(TYPES.UPDATE_ONBOARDING_STEP_COMMAND)
  readonly updateOnboardingStepCommand!: UpdateOnboardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly vueNotifier!: VueNotifier;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  $refs!: {
    source_of_resources: Vue;
    working_information: Vue;
    transactionality: Vue;
    disclaimers: Vue;
  };

  current_step: StepEntity = {
    current_step: '',
    payload: {},
    id: '',
  };

  private readonly step_ids = {
    bank_information: v4(),
  };

  async updateStepData() {
    await this.updateOnboardingStepCommand.execute({
      current_step: this.current_step.current_step,
      id: this.current_step.id,
      payload: {
        financial_information: {
          ...this.$refs.working_information.$data.working_information_view_model.inputs,
          ...this.$refs.transactionality.$data.inputs,
          ...this.$refs.disclaimers.$data.disclaimers_view_model.inputs,
          ...this.$refs.source_of_resources.$data.inputs,
          initial_investment: parseFloat(this.$refs.transactionality.$data.inputs.initial_investment.replaceAll(/[\s,$]/gi, '')),
          maximum_expected_investment: parseFloat(this.$refs.transactionality.$data.inputs.maximum_expected_investment.replaceAll(/[\s,$]/gi, '')),
        },
        agreements: [
          this.$refs.working_information.$data.working_information_view_model.user_agreement,
          ...this.$refs.disclaimers.$data.disclaimers_view_model.user_agreements,
        ],
      },
    });
    await this.createOnboardingStepCommand.execute({
      id: this.step_ids.bank_information,
      current_step: 'bank_information',
      payload: {
        bank_account: '',
        bank_id: '',
        financial_institution: '',
        confirm_bank_information: false,
        want_to_be_contacted_to_add_another_bank_account: false,
        want_to_add_another_bank_account: false,
        agreements: [],
      },
    });
  }

  async created() {
    window.scrollTo(0, 0);
    this.current_step = await this.get_on_boarding_step_query.execute('financial_information');
  }
}
